'use strict'

const FormContainer = require('./containers/FormContainer')

const containerFactory = require('./containers/containerFactory')
const LegacyContainer = containerFactory({defaultSkinName: 'wysiwyg.viewer.skins.area.DefaultAreaSkin', legacyInlineContent: true, includeScrollMixin: true, compType: 'wixapps.integration.components.Area'})
const GroupContainer = containerFactory({defaultSkinName: 'wysiwyg.viewer.components.GroupSkin', includeScrollMixin: false, displayName: 'WixGroupContainer', compType: 'wysiwyg.viewer.components.Group', pointerEventsNone: true})
const Container = containerFactory({defaultSkinName: 'wysiwyg.viewer.skins.area.DefaultAreaSkin', includeScrollMixin: true, compType: 'mobile.core.components.Container'})
const AppWidget = containerFactory({defaultSkinName: 'platform.components.skins.AppWidgetSkin', includeScrollMixin: true, displayName: 'AppWidget', compType: 'platform.components.AppWidget'})
const RefComponent = containerFactory({defaultSkinName: 'skins.core.InlineSkin', includeScrollMixin: true, displayName: 'RefComponent', compType: 'wysiwyg.viewer.components.RefComponent'})

module.exports = {
    FormContainer,
    GroupContainer,
    LegacyContainer,
    Container,
    RefComponent,
    AppWidget
}
